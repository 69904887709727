/*
 * @Author: lishuai lish@zhiding.com.cn
 * @Date: 2023-02-24 10:27:05
 * @LastEditors: lishuai lish@zhiding.com.cn
 * @LastEditTime: 2023-02-24 10:48:30
 * @FilePath: \sst-sjt-online-fe\src\network\reqApi\commom.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import service from "../request";

// GET /common/product/list
export function getProductNameReq(params) {
    return service.get('/common/product/list', {
        params: params,
    })
}

// 生成验证码
// GET /captchaImage
export function getCodeReq() {
    return service.get('/captchaImage', {})
}


