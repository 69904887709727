<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>

<script>
import { saveCurrentRouteKey } from "@/util/util.js";
export default {
    name: 'App',
    data() {
        return {}
    },
    created() {
        /* 防止在安卓系统微信浏览器环境中，用户设置了大字体，导致h5网页布局出现问题 */
        (function () {
            if (typeof WeixinJSBridge == "object" && typeof WeixinJSBridge.invoke == "function") {
                handleFontSize();
            } else {
                if (document.addEventListener) {
                    document.addEventListener("WeixinJSBridgeReady", handleFontSize, false);
                } else if (document.attachEvent) {
                    document.attachEvent("WeixinJSBridgeReady", handleFontSize);
                    document.attachEvent("onWeixinJSBridgeReady", handleFontSize);
                }
            }
            function handleFontSize() {
                WeixinJSBridge.invoke('setFontSizeCallback', { 'fontSize': 0 });
                WeixinJSBridge.on('menu:setfont', function () {
                    WeixinJSBridge.invoke('setFontSizeCallback', { 'fontSize': 0 });
                });
            }
        })();
    },
    methods: {
        toHome: function () {
            this.$store.commit('user/SET_COUNT')
            this.$store.commit('INCRE_ROOT_COUNT')
        }
    },
    computed: {
        count: function () {
            return this.$store.state.user.count
        }
    },
    components: {},
    watch: {
        /**
         * @description: 监听当前spa路由内容
         * @return {*}
         */
        $route: {
            handler(val) {
                // 如果有目标路由的路径，将器写入到本地存储中
                if (val) {
                    if (val.path) {
                        saveCurrentRouteKey(val.path)
                    }
                }
            },
            // 深度观察监听
            deep: true
        }
    }
}
</script>

<style>
@import url("./style/reset.css");

#app {
    /* font-family: SimHei, Avenir, Helvetica, Arial, sans-serif; */
    /* 依据需求，配置本应用使用的字体 */
    font-family: "Noto Sans SC";
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}

/* 防止在ios系统微信浏览器环境中，用户设置了大字体，导致h5网页布局出现问题 */
body {
    -webkit-text-size-adjust: 100% !important;
}
</style>
